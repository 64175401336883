import React from "react"


export default () => (

	<section class="butinuki">
			<span class="kage_moji temp">
			「Listen in browser」ですぐに再生できます↓
			</span>
			<div class="player">
				<iframe class="lazyload" title='サウンドクラウドプレイヤー' width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" data-src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/428261093&amp;color=%23ff5500&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;show_teaser=true"></iframe>
			</div>
		</section>

)