import React from "react"
import { Link } from "gatsby"

import Kiji from "@components/kiji-link"
import KijiLink from "@components/kiji-link-only"
import Youtube from "@components/youtube"

var obj = { animateIn: 	'fadeIn',
            duration: 	0.8,
          }

            console.log(obj)

export default () => (

	<section class="kage_moji">

		<h2 class="huwa">MIXについて</h2>
		<Youtube bangou='8mVZaiXGXh4' tuika_class='' />
		<p class='mb0'>曲や「歌ってみた」の音質を、<br />
			４時間くらいでプロの音にする方法を<br />
			見つけました。	</p>
		<KijiLink kagi='mix' tuika_class='btn aka t-bold yajirusi'>常識を破壊するMIXのやり方</KijiLink>

	</section>
)