import React from "react"

import Metal from '../components/metal'


export default () => (


<section class="kanaami butinuki">

	<Metal/>

	<h2 class="kanaami_title huwa" >カラオケで歌えるで</h2>

	<div class="flex">
		<a class="f_item suiW" href="https://www.joysound.com/web/search/cross?match=1&amp;keyword=e.l.v.n" target="_blank" rel="noopener noreferrer">
			<img class="lazyload maru border" alt="ジョイサウンド"
					src="https://elvn.imgix.net/logo/joysound-l.png"
					data-src="https://res.cloudinary.com/e11/image/upload/v1544763710/joysound.png"/>
			<p class="maru_title to_hide_sma" >JOYSOUND</p>
		</a>

		<a class="f_item suiW" href="http://www.clubdam.com/app/search/searchKaraokeKeywordArtist.html" target="_blank" rel="noopener noreferrer">
			<img class="lazyload maru" alt="ダム"
					src="https://elvn.imgix.net/logo/dam-l.png"
					data-src="https://res.cloudinary.com/e11/image/upload/v1544765915/dam.png"/>
			<p class="maru_title to_hide_sma" >DAM</p>
		</a>
	</div>

</section>

)