import React from "react"
import { Link } from "gatsby"


export default () => (

		<section class="kage_moji butinuki">

			<h2 class="huwa">ぺぺっぺ</h2>

			<div class="suiR">
				<p>ぺぺぺ、ペペペペペ、ぺぺぺっぺぺ。<br/>
					ぺっぺ、ぺぺぺっぺ？<br/>
					ペペペ、ぺぺぺぺぺぺんぺぺ、ぺっぺ<br/>
				</p>
				<img class=	"lazyload hako" alt="逆さまのダンボール箱"
				           	 src=     	"data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
				           	 data-src=	"https://elvn.imgix.net/nennga/2018hako.svg"/>
				<p>
					ぺぺ：<br/>
					ぺーぺんぺぺぺぺんぺんぺぺ、ぺぺぺぺっぺ
				</p>
			</div>

			<Link to="/"><div class="btn suiL aqua">ぺ</div></Link>
			<p class="aqua" style={{fontSize:'0.6em',position:'relative',top:'-4.7em'}}>※「ぺ」は準備中</p>

		</section>

)