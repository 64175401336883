import React from "react"
import SEO from '../components/seo'

import Layout from '../layouts/layout'

import Fanlogo from '../components/fanlogo'
import Soundcloud from '../components/soundcloud'
import Mix from '../components/mix'
import Karaoke from '../components/karaoke'
import Cd from '../components/cd'
import Haisin from '../components/haisin'
import Pepe from '../components/pepe'

export default () => (



<Layout>

	<SEO title="E.L.V.N Top" keywords={[`E.L.V.N`, `elvn`]} />

	<Fanlogo/>

	<Soundcloud/>

	<Mix/>

	<Karaoke/>

	<Cd/>

	<Haisin/>

	<Pepe/>

</Layout>
)
