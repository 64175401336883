import React from "react"
import Metal from '../components/metal'


export default () => (

<section class="kanaami butinuki">

		<Metal/>

		<h2 class="kanaami_title huwa" >どこでも聴けるで</h2>

		<div class="flex">
			<a class="f_item_s suiW" href="https://open.spotify.com/artist/219XzPMmtHGUDe8bJeWo6M?nd=1" target="_blank" rel="noopener noreferrer">
				<img class="lazyload maru_s border_s" alt="スポティファイ"
						src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
						data-src="https://elvn.imgix.net/logo/spotify.svg"/>
				<p class="maru_title_s" >Spotify</p>
			</a>

			<a class="f_item_s suiW" href="https://itunes.apple.com/jp/artist/e.l.v.n/id425636195" target="_blank" rel="noopener noreferrer">
				<img class="lazyload maru_s" alt="アップルミュージック"
						src="https://elvn.imgix.net/logo/apmusic-l.jpg"
						data-src="https://elvn.imgix.net/logo/apmusic.jpg"/>
				<p class="maru_title_s" >Apple Music</p>
			</a>

			<a class="f_item_s suiW" href="https://music.line.me/artist/mi000000000013af94" target="_blank" rel="noopener noreferrer">
				<img class="lazyload maru_s" alt="ラインミュージック"
						src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
						data-src="https://elvn.imgix.net/logo/linemusic.png"/>
				<p class="maru_title_s" >LINE MUSIC</p>
			</a>
		</div>
		<span>- Amazonプライムミュージックなど その他多数 -</span>

</section>

)