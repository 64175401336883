import React from 'react'
import lazysizes from 'lazysizes'
import './global.css'

import Header from '../components/header'
import Footer from '../components/footer'

export default ({ children }) => {

		// メルマガページから変遷した時に、背景を白に戻す機能
		if (typeof document !== `undefined`) {
			document.body.classList.remove('haikei-kuro')
		}

	return (
		<>


			<Header />

			<main class="t-center b-center seigen">

				{children}

			</main>

			<Footer />

		</>
	)
}