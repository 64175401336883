import React from "react"


export default () => (

<section class="kage_moji">

	<h2 class="huwa">CD買えるで</h2>
	<a href="https://www.amazon.co.jp/MOON-E-L-V-N/dp/B007ZO6M1U/" target="_blank" rel="noopener noreferrer">

<img class=	"cd lazyload suiR kage" alt="CDジャケット"
 src=      	"https://elvn.imgix.net/cd/jacket1.png?w=200&auto=format,compress&q=0"
 data-src= 	"https://elvn.imgix.net/cd/jacket1-k75.jpg"/>

		<br/><span class="btn suiL aka">詳しく</span>
	</a>

</section>

)